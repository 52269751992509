import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVotingIsLoading, getVotingResults } from 'entities/Voting/model/selectors/getVoting';
import { fetchTestResults } from 'widgets/TestPassingResultsWidget/api';
import { useWindowResolution } from 'shared/lib/hooks/useWindowResolution';
import cls from './TestPassingResultsWidget.module.scss';
var TestPassingResultsWidget = function (_a) {
    var _b;
    var testPassingId = _a.testPassingId;
    var dispatch = useDispatch();
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var width = useWindowResolution().width;
    var results = useSelector(getVotingResults);
    var isLoading = useSelector(getVotingIsLoading);
    var questions = (_b = results === null || results === void 0 ? void 0 : results.statistic) === null || _b === void 0 ? void 0 : _b.question;
    useEffect(function () {
        dispatch(fetchTestResults(testPassingId));
    }, [testPassingId]);
    return (_jsxs("div", { children: [_jsx("h3", { style: { marginBottom: 8 }, children: "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B \u043F\u0440\u043E\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F \u0443\u0440\u043E\u043A\u0430" }), _jsxs("table", { className: "table", children: [_jsx("thead", { className: cls.tableHeader, children: _jsxs("tr", { children: [_jsx("th", { children: "\u2116" }), _jsx("th", { children: "\u041E\u043F\u0443\u0431\u043B\u0438\u043A\u043E\u0432\u0430\u043D" }), _jsx("th", { children: "\u0422\u0438\u043F \u043E\u0442\u0432\u0435\u0442\u043E\u0432" }), _jsx("th", { children: "\u041F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u043E \u043E\u0442\u0432\u0435\u0442\u0438\u043B" }), _jsx("th", { children: "\u041F\u0440\u043E\u0445\u043E\u0434\u0438\u043B" }), _jsx("th", { children: "\u0420\u043E\u043B\u044C" }), _jsx("th", { children: "\u0411\u0430\u043B\u043B\u044B" }), _jsx("th", { children: "\u0412\u0440\u0435\u043C\u044F \u043F\u0440\u043E\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F" }), _jsx("th", {})] }) }), width > 1159
                        ? (_jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { children: "1. \u041A\u0442\u043E \u0433\u043E\u0442\u043E\u0432\u0438\u0442 \u043C\u0430\u0442\u0435\u0440\u0438\u0430\u043B\u044B \u0434\u043B\u044F \u0432\u044B\u0441\u0442\u0443\u043F\u043B\u0435\u043D\u0438\u044F (\u043F\u0440\u0435\u0437\u0435\u043D\u0442\u0430\u0446\u0438\u044E, \u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0438, \u0440\u0435\u043A\u0432\u0438\u0437\u0438\u0442, \u043A\u043E\u0442\u043E\u0440\u044B\u0439 \u0431\u0443\u0434\u0435\u0442 \u0432 \u043A\u0430\u0434\u0440\u0435)?." }), _jsx("td", { children: "\u0414\u0430" }), _jsx("td", { children: "\u041E\u0434\u0438\u043D \u043E\u0442\u0432\u0435\u0442" }), _jsx("td", { children: "\u041D\u0435\u0442\t" }), _jsx("td", { children: "\u041D\u0435\u0442\t" }), _jsx("td", { children: "\u0411\u0430\u043B\u043B\u044B" }), _jsx("td", { children: "-" }), _jsx("td", { children: "-" })] }) }, 1))
                        : (_jsx("div", { className: cls.card, children: _jsxs("div", { className: cls.userCard__content, children: [_jsxs("p", { children: [_jsx("b", { children: "\u2116: " }), "1"] }), _jsxs("p", { children: [_jsx("b", { children: "ID:" }), " asdasd"] }), _jsxs("p", { children: [_jsx("b", { children: "Email:" }), " asdasd"] }), _jsxs("p", { children: [_jsx("b", { children: "\u0421\u0442\u0430\u0442\u0443\u0441:" }), " asdasdasd"] }), _jsxs("p", { children: [_jsx("b", { children: "\u0422\u0438\u043F:" }), " asdasd"] }), _jsxs("p", { children: [_jsx("b", { children: "\u0420\u043E\u043B\u044C:" }), " asdasdasd"] })] }) }, 1))] })] }));
};
export { TestPassingResultsWidget };
